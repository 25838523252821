import React, { useState, useEffect } from 'react';
import './Contact.css';
import photo1 from '../image/NE logo copy.png';
import photo2 from '../image/rumeli logo.png';
import photo3 from '../image/nizwa mining logo.png';
import photo4 from '../image/wafra engineering logo.png';
import whatsappIcon from '../image/watsapp.jpg'; // Add a WhatsApp icon
import gmailIcon from '../image/gmail.png'; // Add a Gmail icon
import contactIcon from '../image/contact.png'; // Add a Contact icon

function ISSA() {
  const [, setIsDesktop] = useState(false);
  const formData = {
    name: 'ISSA YAHYA AL AMRI',
    phone: '+968 99261830',
    email: 'issa@alrumeli.com',
    company: 'Nizwa Engineering',
    website: 'https://alrumeli.com',
    website2: 'https://nizwaeng.com',
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleAddToWhatsApp = () => {
    const whatsappUrl = `https://wa.me/${formData.phone.replace(/\D/g, '')}`;
    window.open(whatsappUrl, '_blank');
  };

  const handleAddToGmail = () => {
    const mailtoUrl = `mailto:${formData.email}?subject=Add%20to%20Contacts&body=Hi%20${formData.name},%20I%20would%20like%20to%20add%20you%20to%20my%20contacts.`;
    window.open(mailtoUrl, '_blank');
  };

  const handleDownloadVCard = () => {
    const vCard = `BEGIN:VCARD
VERSION:3.0
N:AL AMRI ;ISSA YAHYA 
FN:${formData.name}
TEL:${formData.phone}
EMAIL:${formData.email}
ORG:${formData.company}
URL:${formData.website}
URL:${formData.website2}
END:VCARD`;

    const blob = new Blob([vCard], { type: 'text/vcard' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'issa-contact.vcf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="contact-card-container">
      <div className="photo-container">
        <img src={photo1} alt="Nizwa Engineering Logo" />
        <img src={photo2} alt="Rumeli Logo" />
        <img src={photo3} alt="Nizwa Mining Logo" />
        <img src={photo4} alt="Wafra Engineering Logo" />
      </div>

      <div className="card-content">
        <h2>{formData.name}</h2>
        <div className="contact-details">
          <p><strong>Phone:</strong> {formData.phone}</p>
          <p><strong>Email:</strong> {formData.email}</p>
          <p><strong>Company:</strong> {formData.company}</p>
          <p>
            <strong>Websites:</strong>{' '}
            <a href={formData.website} target="_blank" rel="noopener noreferrer">
              {formData.website.replace(/https?:\/\//, '')}
            </a>{' '}
            |{' '}
            <a href={formData.website2} target="_blank" rel="noopener noreferrer">
              {formData.website2.replace(/https?:\/\//, '')}
            </a>
          </p>
        </div>

        <div className="icon-group">
          <img
            src={whatsappIcon}
            alt="WhatsApp"
            className="action-icon"
            onClick={handleAddToWhatsApp}
          />
          <img
            src={gmailIcon}
            alt="Gmail"
            className="action-icon"
            onClick={handleAddToGmail}
          />
          <img
            src={contactIcon}
            alt="Add Contact"
            className="action-icon"
            onClick={handleDownloadVCard}
          />
        </div>
      </div>
    </div>
  );
}

export default ISSA;
